import { ReactComponent as AnBracket } from './angle-bracket.svg';


const AngleBracket = ({fill, stroke, isOpen}:{fill:string, stroke:string; isOpen: boolean;}) => (
  <div  className={`angleBracketHolder ${isOpen?'open':'closed'}`}>
    <AnBracket fill={fill} stroke={stroke} />
  </div>
);

export default {AngleBracket};

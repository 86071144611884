import { useState } from "react";
import moment from 'moment-timezone';

const DateRangePicker = ({setDateRange, dateRange}
    :{setDateRange:any, dateRange:any}) => {
    const [range, setRange] = useState('week');
    const parentRange = dateRange;
    const pickRange = (rangeKey: string) =>{
        let endDate = new Date();
        let start;
        switch (rangeKey) {
            case '3hr':
                endDate.setHours(endDate.getHours(),endDate.getMinutes(),0,0);
                start = new Date(endDate.getTime() - 3 * 60 * 60 * 1000);
            break;
            case 'day':
                endDate.setHours(endDate.getHours(),0,0,0);
                start = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
            break;
            case 'week':
                endDate.setHours(0,0,0,0);
                start = new Date(endDate.getTime() - 7 * 24 * 60 * 60 * 1000);
            break;
            case 'month':
                endDate.setHours(0,0,0,0);
                start = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);
            break;
            case 'year':
                endDate.setHours(0,0,0,0);
                start = new Date(endDate.getTime() - 365 * 24 * 60 * 60 * 1000);
            break;
            case 'back':
                rangeKey = parentRange.range;
                endDate = parentRange.start;
                switch(rangeKey) {
                    case '3hr':
                        start = moment(endDate).subtract(3, 'h').toDate();
                        break;
                    case 'day':
                        start = moment(endDate).subtract(1, 'd').toDate();
                        break;
                    case 'week':
                        start = moment(endDate).subtract(1, 'w').toDate();
                        break;
                    case 'month':
                        start = moment(endDate).subtract(1, 'M').toDate();
                        break;
                    case 'year':
                        start = moment(endDate).subtract(1, 'y').toDate();
                        break;
                }
            break;
            case 'forward':
                rangeKey = parentRange.range;
                start = parentRange.end;
                switch(rangeKey) {
                    case '3hr':
                        endDate = moment(start).add(3, 'h').toDate();
                        break;
                    case 'day':
                        endDate = moment(start).add(1, 'd').toDate();
                        break;
                    case 'week':
                        endDate = moment(start).add(1, 'w').toDate();
                        break;
                    case 'month':
                        endDate = moment(start).add(1, 'M').toDate();
                        break;
                    case 'year':
                        endDate = moment(start).add(1, 'y').toDate();
                        break;
                }
            break;
            default:
            break;
        }
        setRange(rangeKey);
        if(endDate.valueOf() > Date.now()) {
            endDate = moment(new Date()).subtract(1, 'm').toDate();
        }
        if(start.valueOf() < Date.now() && endDate.valueOf() < Date.now()){
            setDateRange({start, end: endDate , range: rangeKey});
        } else {
            console.debug(`Date range extends into future`,{start, end: endDate } );
        }
    };
    const nowish = moment(new Date()).subtract(3, 'h');
    const enableForward = dateRange.end.valueOf() < (nowish.valueOf());
    return (
        <div className="dateRangePicker">
            <b>Range:</b>
            <span onClick={() => pickRange('3hr')} className={range ==="3hr"?"highlight":""}>3hr</span>
            <span onClick={() => pickRange('day')} className={range ==="day"?"highlight":""}>24 hrs</span>
            <span onClick={() => pickRange('week')} className={range ==="week"?"highlight":""}>Week</span>
            <span onClick={() => pickRange('month')} className={range ==="month"?"highlight":""} >Month</span>
            <span onClick={() => pickRange('year')} className={range ==="year"?"highlight":""}>Year</span>
            <span onClick={() => pickRange('back')} className="range-back highlight">⬅️</span>
            <span onClick={() => pickRange('forward')} className={`range-forward ${enableForward? 'highlight': ''}` }>➡️</span>
        </div>
    )
};

export default DateRangePicker;
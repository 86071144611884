class LiveDataServiceClient {
  webSocketServer:WebSocket|undefined = undefined;

  _dispatch:undefined|Function = undefined;

  _currentObject:string|undefined = undefined;

  subscriptionMessageGenerator = (id:string, payloadType = 'location', segment_requests:any  = {"consumption":["p"]}) => {
    return {
      type: 'subscribe',
      payload: {
        type: payloadType,
        id,
        segment_requests
      }
    };
  };

  authenticationMessageGenerator = (token:string) => {
    return {
      type: 'authorize',
      payload: {
        access_token: token
      }
    };
  }

  _WebSocketManagerStates = {
    UNCONNECTED: 'UNCONNECTED',
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    SUBSCRIBING: 'SUBSCRIBING',
    UNSUBSCRIBING: 'UNSUBSCRIBING',
    DISCONNECTING: 'DISCONNECTING'
  };

  _currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
  private _token: string;
 

/*
 * LiveDataServiceClient constructor,
 * instantiate like `new LiveDataServiceClient(authtoken)`
 * then:
 *  set the Location ID
 *  set the Dispatch function
 *  call initializeSubscription()
 *  handle messages in the Dispatch function
 *  call endSubscription() when done 
 */

  constructor(token:string) {
    this.webSocketServer = undefined;
    this._token = token;
    this._currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
  }

  setDispatch = (dispatch:Function) => {
    this._dispatch = dispatch;
  }
  getDispatch = () => {
    return this._dispatch;
  };

  messageHandler = (payload:any) => {
    if(payload) {
      try {
      const data = JSON.parse(payload)
      this._dispatch && this._dispatch(data);
      } catch(e) {
        console.error('Error parsing websocket payload', e);
      }
    }
  };

  getSubscriptionObjectId = () => {
    return this._currentObject;
  }

  setSubscriptionObjectId = (id:string) => {
    this._currentObject = id;
  }

  endSubscription = () => {
    if (typeof this.webSocketServer !== 'undefined') {
      this.webSocketServer.close();
    }
  }

  initializeSubscription = (options: any) => {
    if(this._currentWebSocketStatus === this._WebSocketManagerStates.SUBSCRIBING){
      console.debug(`web socket is subscribing...`);
      return;
    }
    const authToken = this._token;
    if(!authToken) {
      return;
    }
    // check that the Location ID is set
    if (!this._currentObject) {
      return;
    }
    // check that the websocket is not already connected
    if (this._currentWebSocketStatus === this._WebSocketManagerStates.CONNECTED
      || this.webSocketServer instanceof WebSocket) {
      this.endSubscription();
    }
    // check that the Dispatch function is set
    if (!this._dispatch || typeof this._dispatch !== 'function') {
      return;
    }
    const authMessage = this.authenticationMessageGenerator(authToken);
    this.webSocketServer = new WebSocket(`${process.env.REACT_APP_LDS_API_ROOT}`);
    this._currentWebSocketStatus = this._WebSocketManagerStates.SUBSCRIBING;
    this.webSocketServer.addEventListener('open', () => {
      if (!this.webSocketServer) return;
      this.webSocketServer.send(JSON.stringify(authMessage));
      this._currentWebSocketStatus = this._WebSocketManagerStates.CONNECTED;
      const segments = options?.consumptionSegments || undefined;
      const payload = this.subscriptionMessageGenerator(this._currentObject||'', 'location', segments);
      console.debug(`WS sending subscription message `, payload, JSON.stringify(payload));
      this.webSocketServer.send(JSON.stringify(payload));
      this.webSocketServer.addEventListener("message", (event) => {
        this.messageHandler(event.data);
      });
      this.webSocketServer.addEventListener('close', () => {
        this._currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
        
      });
      this.webSocketServer.addEventListener('error', (e) => {
        console.log('[WebSocketManager Error]', e);
        this._currentWebSocketStatus = this._WebSocketManagerStates.UNCONNECTED;
      });
    });
  }
}

export default LiveDataServiceClient;


import Aspect from "./Aspect";

import Category from "./Category";

interface IInsight {
  timestamp: string;
  data: ICategory[];
}

interface ICategory {
  key: string;
  label: string;
  data: IRegister[]|IAspectValue[];
}

interface IRegister {
  label: string;
  data: IAspectValue[];
}

interface IAspectValue {
  key: string;
  label: string;
  value: string;
}



/**
 * {
                    timestamp: '2024 01 26T12:00:00.000Z',
                    data: [{
                        key: "hvac",
                        label: "HVAC",
                        data:  [ {
                            label: 'A/C #1',
                            data:[{
                                key: "filter_status",
                                label: "Filter",
                                value: "Dirty",
                                },{
                                key: "capacitor_status",
                                label: "Capacitor",
                                value: "Good",
                                },{
                                key: "performance_estimate",
                                label: "Performance",
                                value: "Good",
                                },{
                                key: "condenser_cap_herm_label_uf",
                                label: "Compressor capacitor label (uF)",
                                value: "45.0",
                                },{
                                key: "condenser_cap_fan_label_uf",
                                label: "Fan capacitor label (uF)",
                                value: "5.0",
                                },{
                                key: "condenser_cap_tolerance_percent",
                                label: "Capacitor tolerance (%)",
                                value: "5.0",
                              }]
                            },
                            {                              
                            label: 'A/C #2',
                            data:[{
                                key: "filter_status",
                                label: "Filter",
                                value: "Dirty",
                                },{
                                key: "capacitor_status",
                                label: "Capacitor",
                                value: "Good",
                                },{
                                key: "performance_estimate",
                                label: "Performance",
                                value: "Good",
                                },{
                                key: "condenser_cap_herm_label_uf",
                                label: "Compressor capacitor label (uF)",
                                value: "50.0",
                                },{
                                key: "condenser_cap_fan_label_uf",
                                label: "Fan capacitor label (uF)",
                                value: "7.5",
                                },{
                                key: "condenser_cap_tolerance_percent",
                                label: "Capacitor tolerance (%)",
                                value: "6.0",
                                }]
                          }
                          ],
                          },
                        { 
                        key: "efficiency",
                        label: "Efficiency",
                        data:[
                        {
                            key: "attic_insulation",
                            label: "Insulation",
                            value: "Excellent",
                            },{
                            key: "attic_insulation_depth_inches",
                            label: "Insulation Depth (Inches)",
                            value: "19",
                            }]
    }
    ]
}
 */


const Insights = ({data}:{data:IInsight}) => {
    if(!data || !data.data) {
        return null;
    }
  const accordians = data.data.map((category:ICategory) => {
    return (
      <div className='accordian' key={category.key}>
        <div className='accordianCategory'>
            <Category category={category} />
        </div>
      </div>
    );
  });


  return (
    <div className={`insightsPage`}>
      <div className="insightsDasboard">
        <h1 className={`insightsHeader`}>Heating &amp; Cooling Status</h1>
        <p className={`insightsCopy`}>Check the status of your heating and cooling equipment to ensure they're functioning properly and efficently, and not leading to unnecessary expenses.</p>
        {accordians}
      </div>
      <div className="ctaOne ctaBox">
        <h4>Order A/C Filters</h4>
        <p>Today may be the right time for a new A/C filter. Shop now and get your new filter delivered to your home.</p>
        <img src="https://via.placeholder.com/150" alt="A/C Filter" className="ctaImage" />
        <button className="ctaButton">Shop Now</button>
      </div>
      <div className="ctaTwo ctaBox">
        <h4>SCHEDULE AN ENERGY AUDIT</h4>
        <p>Today may be the right time for a new A/C filter. Shop now and get your new filter delivered to your home.</p>
        <img src="https://via.placeholder.com/150" alt="A/C Filter" className="ctaImage" />
        <button className="ctaButton">Call Now (844) 629-2872</button>
      </div>
    </div>
  );
};

export default Insights;
export type { IInsight, ICategory, IRegister, IAspectValue };

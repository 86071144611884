import {useState, useEffect, useRef} from 'react';
import LoadingWidget from '../../components/LoadingWidget';

const AcceptClaimState = {
  loading: 'loading',
  getting_user: 'getting_user',
  creating_user: 'creating_user',
  activating_claim: 'activating_claim',
  claiming_location: 'claiming_location',
  done: 'done',
  error_creating_user: 'error_creating_user',
  error_activating_claim_or_getting_location: 'error_activating_claim_or_getting_location',
}

const AcceptClaim = (
    {publicService, user, mClaim, auth0, prependUsersLocations}:
    {publicService:any, user: any, mClaim:string, auth0:any, prependUsersLocations: Function}) => {
  const [pageState,updatePageState] = useState(AcceptClaimState.loading);
  const setPageState = (state: string) => {
    console.log(`Setting page state to ${state}`);
    updatePageState(state);
  };
  const dataFetchedRef = useRef(false);

  const {sub=""} = user;
  if(!sub) {
    throw new Error('Invalid User');
  }

  useEffect(() => {
    if(dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    const getData = async () => {
      try {
        const claim = await publicService.activateClaimByCode(mClaim);
        setPageState(AcceptClaimState.activating_claim)
        const location = await publicService.getLocationByClaim(claim);
        setPageState(AcceptClaimState.claiming_location);
        setPageState(AcceptClaimState.done);
        // get rid of mclaim in URL bar
        const params = new URLSearchParams(window.location.search);
        params.delete('mclaim');
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
        // the following will cause the dashboard to load the new location
        // and change the tabs
        await prependUsersLocations(location);
        
      }
      catch(err:any) {
        setPageState(AcceptClaimState.error_activating_claim_or_getting_location);
        console.log("Error", err);
      }
    }
    getData();
  }, []); 

  return (<>
  <div style={{textAlign: "center"}}>
      {pageState === AcceptClaimState.loading && <div>
        <LoadingWidget message='Loading' vanish={false} />
      </div>}
      {pageState === AcceptClaimState.done && <div>
        <h3>Redirecting you to your dashboard.</h3>
      </div>}
      {pageState === AcceptClaimState.activating_claim && <div>
        <h3>Step 1.</h3>
      </div>}
      {pageState === AcceptClaimState.claiming_location && <div>
        <h3>Step 2.</h3>
      </div>}
      {pageState.includes('error') && <div>
        <h4>Error handling claim.</h4>
      </div>}
    </div>
  </>);
}

export default AcceptClaim;
import { useState } from "react";

const MeasurePicker = ({setMeasureParent}
    :{setMeasureParent:any}) => {
        const [measure, setMeasure] = useState('e');
        const toggleMeasure = (meas: string) => {
            setMeasure(meas);
            setMeasureParent(meas);
        };

    return (
    <div className="unitPicker">
        <span className={measure!=="p"?"highlight":"disabled"} onClick={() => toggleMeasure('e')}>kWh</span>
        <span className={measure==="p"?"highlight":"disabled"} onClick={() => toggleMeasure('p')}>W</span>
        {/* <span className="disabled noclick">$</span> */}
    </div>
    )
};

export default MeasurePicker;
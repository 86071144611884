import { useEffect, useState, useRef } from 'react';
import LoadingWidget from '../LoadingWidget';
import ErrorWidget from '../ErrorWidget';
import { getConsumptionQuerySegments } from '../History/data';

const Live = ({locationId, liveDataService, publicDataService}
  :{locationId:string, liveDataService:any, publicDataService:any}) => {
  const [consumptionQuerySegments, setConsumptionQuerySegments] = useState<{ [key: string]: string; }>({});
  // useRef to keep track of the previous value
  const timeStampLastMessage = useRef(Date.now());
  const timeKeeper = useRef(setInterval(() => {
    if(timeStampLastMessage.current + 3000 < Date.now()) {
      timeOutError();
    }
  }, 3000));
  const timeOutError = () => {
    setTimeout(() => { setLoading(false);}, 1000);
    setVanish(true);
    setError(true);
    setVanishError(false);
    setErrorMessage("Error Subscribing");
  }
  const prevLocationId = useRef(locationId);
  const [loading, setLoading] = useState(true);
  const [vanish, setVanish] = useState(false);
  const [vanishError, setVanishError] = useState(false);
  const [highWaterMark, setHighWaterMark] = useState(1);
  const [liveData, updateLiveData] = useState(0);
  const [liveConsumptionData, updateLiveConsumptionData] = useState<any>({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error Subscribing");
  let subscribed = useRef(false);
  const formatterNumber = new Intl.NumberFormat(undefined);

  const segmentSorter = (a: string,b: string)=>{
    if (consumptionQuerySegments[a]?.toLowerCase() < consumptionQuerySegments[b]?.toLowerCase()) {
      return -1;
    }
    if ( consumptionQuerySegments[a]?.toLowerCase() >  consumptionQuerySegments[b]?.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    let needsNewSubscription = false;
    if(prevLocationId.current !== locationId) {
      prevLocationId.current = locationId;
      needsNewSubscription = true;
      setLoading(true);
      setVanish(false);
      setError(false);
    }
    const setLiveData = (data:any) => { 
      setLoading(false);
      setError(false);
      setVanishError(true);
      timeStampLastMessage.current = Date.now();
      updateLiveConsumptionData(data);
    };
    const setSubscription = async () => {
      try {
        subscribed.current = true;
        const freshQuerySegments = await getConsumptionQuerySegments(publicDataService, locationId);
        setConsumptionQuerySegments(freshQuerySegments);
        const consumptionSegments :any = {};
        Object.keys(freshQuerySegments).forEach((segment)=>{
          consumptionSegments[segment] = ['p'];
        });
        updateLiveConsumptionData([]);
        console.debug('Consumption query segments', freshQuerySegments, consumptionSegments);
        liveDataService.setSubscriptionObjectId(locationId);
        liveDataService.setDispatch(setLiveData);
        liveDataService.initializeSubscription({consumptionSegments});
      } catch (e:any) {
        setErrorMessage(e.message||errorMessage);
        setError(true);
        setLoading(false);
      }
    };
    if (needsNewSubscription || !subscribed.current){
      setSubscription();
    }
  }, [locationId, liveDataService, loading, error, errorMessage]);

  let heightPercent = liveData/highWaterMark * 100;
  if (heightPercent > 100) heightPercent = 100;
  return (
    <div className="live-consumption-widget-container">
    {loading && <LoadingWidget message={'Subscribing to Live Data'} vanish={vanish} />}
    {error && <ErrorWidget message={errorMessage} vanish={vanishError}/>}
    <div className='live-consumption-segments'>{liveConsumptionData?.data &&
      Object.keys(liveConsumptionData.data).sort(segmentSorter).map(key=>{
        const val = liveConsumptionData.data[key]['p'];
        const width =  (val<0) ? Math.log2(Math.abs(val)) * 70: Math.max(Math.log2(val) * 70, 18);
      return (
        <div className='live-consumption-segment'>
          <h4 className='live-consumption-label'>{consumptionQuerySegments[key]}</h4>
          <div className={'live-consumption-bar ' + (val< 0 ? 'negative-consumption' : '')} style={{width: width}}></div>
          <div className='live-consumption-value'>{formatterNumber.format(Math.round(val))}W</div>
        </div>
        )
      })
    }</div>
    </div>);
};

export default Live;
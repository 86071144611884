import { useEffect, useState, useRef } from 'react';
import LoadingWidget from '../LoadingWidget';
import ErrorWidget from '../ErrorWidget';
import { getQuerySegments } from '../../components/History/data';

const Live = ({locationId, liveDataService, publicService}
  :{locationId:string, liveDataService:any, publicService:any}) => {
  // useRef to keep track of the previous value
  const timeStampLastMessage = useRef(Date.now());
  const timeKeeper = useRef(setInterval(() => {
    if(timeStampLastMessage.current + 30000 < Date.now()) {
      timeOutError();
    }
  }, 3000));
  const timeOutError = () => {
    setTimeout(() => { setLoading(false);}, 1000);
    setVanish(true);
    setError(true);
    setVanishError(false);
    setErrorMessage("Error Subscribing");
  }
  const prevLocationId = useRef(locationId);
  const [loading, setLoading] = useState(true);
  const [vanish, setVanish] = useState(false);
  const [vanishError, setVanishError] = useState(false);
  const [highWaterMark, setHighWaterMark] = useState(1);
  const [liveData, updateLiveData] = useState<any>({consumption: {p:0}, production: {p:0} });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error Subscribing");
  const subscribed = useRef(false);
  const formatterNumber = new Intl.NumberFormat(undefined);
  const solar = useRef(false);

  const hasSolar = async (locId: any) => {
    let qsProduction;
    try{
    const qs = await getQuerySegments(locId, publicService) ;
    qsProduction = qs.find((l:any)=>{return l.id === 'production'});
    solar.current = !!qsProduction;
    } catch(e){
      throw new Error(`Can't connect to live data`);
    }
    return !!qsProduction;
  }
  useEffect(() => {
    let needsNewSubscription = false;
    if(locationId?.length > 0 && prevLocationId.current !== locationId) {
      prevLocationId.current = locationId;
      needsNewSubscription = true;
      liveDataService.endSubscription();
      setLoading(true);
      setVanish(false);
      setError(false);
      updateLiveData({consumption: {p:0}, production: {p:0}, grid_net: {p:0} })
    }
    const setLiveData = (data:any) => {
      setLoading(false);
      if(error) {
        setTimeout(() => {
          setError(false);
        }, 1000);
        setVanish(true);
      }
      const { data: {consumption: {p = 0} = {}} = {} } = data;
      if (p > highWaterMark) {
        if(typeof p !== 'number') setHighWaterMark(parseFloat(p));
        else setHighWaterMark(p);
      }
      timeStampLastMessage.current = Date.now();
      updateLiveData(data);
    };
    const setSubscription = async () => {
      try {
        subscribed.current = true;
        //if Solar
        const solar = await hasSolar(locationId);
        const payload = (solar) ? {"consumption":["p"], "production": ["p"], "grid_net": ["p"]} :
                                  {"consumption":["p"]};
        liveDataService.setSubscriptionObjectId(locationId);
        liveDataService.setDispatch(setLiveData);
        liveDataService.initializeSubscription({consumptionSegments: payload});
        console.debug(`subscribing to live data feed for location ${locationId} with payload `, payload);
      } catch (e:any) {
        setErrorMessage(e.message||errorMessage);
        setError(true);
        setLoading(false);
      }
    };
    if (needsNewSubscription || (!subscribed.current && locationId?.length > 0) ){
      setSubscription();
    }
  }, [locationId, loading, error, errorMessage]);

  let heightPercent = liveData/highWaterMark * 100;
  if (heightPercent > 100) heightPercent = 100;
  return (
    <div className={`live-data-widget-container ${(solar.current && !error) ? 'has-solar' : ''}`}>
    {loading && <LoadingWidget message={'Subscribing to Live Data'} vanish={vanish} />}
    {error && <ErrorWidget message={errorMessage} vanish={vanishError}/>}
    <div className={`live-data-widget ${loading || error?'live-loading':'live-loaded'}`}>
    {!loading &&
      <>
      <div className="live-data-measure live-data-measure-consumption">
      <div className="live-data-widget-label">POWER USE</div>
      <span className="live-data-widget-value">{formatterNumber.format(Math.round(liveData?.data?.consumption?.p))}<span>W</span></span>
      </div>
    {solar.current &&
      <>
      <div className="live-data-measure live-data-measure-solar">
        <div className="live-data-widget-label">SOLAR</div>
        <span className="live-data-widget-value">{formatterNumber.format(Math.round(liveData?.data?.production?.p))}<span>W</span></span>
      </div>
      <div className="live-data-measure">
        <div className="live-data-widget-label">GRID</div>
        <span className="live-data-widget-value">{formatterNumber.format(Math.round(liveData?.data?.grid_net?.p))}<span>W</span></span>
      </div>
      </>
    }
    </>
    }
  </div>
  </div>);
};

export default Live;

import exp from "constants";
import PublicService from '../../services/Public';
import { IQuerySegment, IRegister } from "../../../../../services/public-service/src/domain/entities/querySegment";



const querySegmentCategoryisConsumption = (querySegment: IQuerySegment) => {
    let result :boolean = false;
    if(querySegment.type === 'register'){
      const register = querySegment.ref as IRegister
      result = (!register.battery && !register.production && !register.grid); // && !querySegment.ref.grid
    } else {
      result  = querySegment.registers?.reduce((accumlulator: any, register: any) => {
        return accumlulator && (!register.battery && !register.grid && !register.production);
      }, true) || true;
    }
    return result;
  };
 const getQuerySegments = async (locationId: any, publicService:any) => {
    const querySegmentResponse = await publicService.getQuerySegmentsByLocation(locationId);
    return querySegmentResponse;
 }
  const getConsumptionQuerySegments = async (publicService:any, locationId: any) => {
    const querySegmentResponse = await publicService.getQuerySegmentsByLocation(locationId);
    const hdsQueryBody = querySegmentResponse.reduce((accumulator:any, current:IQuerySegment)=> {
      if(current.id === 'unmeasured_consumption'){
        accumulator[current.id] = current.label;
        return accumulator;
      }
      //TODO update due to schema changes. `ref.category` has been refactored to `ref.categories: []`
      if(current.type === "register" && !((current.ref as IRegister).categories.length > 1 ) && querySegmentCategoryisConsumption(current)){
        accumulator[current.id] = current.label;
        return accumulator;
      }
      if(current.type === "category" && querySegmentCategoryisConsumption(current)) {
        accumulator[current.id] = current.label;
        return accumulator;
      };
      return accumulator;
    }, {});
    return hdsQueryBody;
  };
const retrieveData = async ({locationId, publicService, tab, measure, dateRange, showError, signal}:{locationId:string, publicService:PublicService, tab: string, measure: string, dateRange: any, showError:any, signal:any}) => {
  try{
      const querySegmentResponse = await publicService.getQuerySegmentsByLocation(locationId);
      console.debug(`These query segments are available for this location (${locationId}):`, querySegmentResponse);
      if (!querySegmentResponse || querySegmentResponse.length> 100 ){
        showError(new Error('There is no data for this location.'));
        return;
      }
      const _querySegmentLabelLookup :any = {};
      if(!Array.isArray(querySegmentResponse)){
        //TODO ? showError, return?
        throw new Error('No query segments available for this location.');
      }
      if(tab === 'consumption'){
        const hdsQueryBody = querySegmentResponse.reduce((accumulator:any, current:IQuerySegment)=> {
          if(current.id === 'unmeasured_consumption'){
            accumulator[current.id] = [measure];
            _querySegmentLabelLookup[current.id] = current.label;
            return accumulator;
          }
          //TODO update due to schema changes. `ref.category` has been refactored to `ref.categories: []`
          if(current.type === "register" && !((current.ref as IRegister).categories.length > 1 ) && querySegmentCategoryisConsumption(current)){
            _querySegmentLabelLookup[current.id] = current.label;
            accumulator[current.id] = [measure];
            return accumulator;
          }
          if(current.type === "category" && querySegmentCategoryisConsumption(current)) {
            accumulator[current.id] = [measure];
            _querySegmentLabelLookup[current.id] = current.label;
            return accumulator;
          };
          return accumulator;
        }, {});
        const hdsResponse = await publicService.getHistoricalDataByQuerySegmentsForChart(locationId, dateRange.start, dateRange.end || new Date(),  hdsQueryBody, dateRange.interval || '1h', signal);
        return {data: hdsResponse, querySegmentLookup : _querySegmentLabelLookup};
    } else {
      const targets = ['consumption', 'grid_net', 'production'];
      const hdsQueryBody = querySegmentResponse.reduce((accumulator:any, current:any)=> {
        if(targets.includes(current.id)){
          accumulator[current.id] = [measure];
          _querySegmentLabelLookup[current.id] = current.label;
          return accumulator;
        }
        return accumulator;
      }, {});
      console.debug(`fetching Net data for location ${locationId} using these query segments:`, hdsQueryBody);
      const graphEndDate = dateRange.end || new Date();
      const hdsResponse = await publicService.getHistoricalDataByQuerySegmentsForChart(locationId, dateRange.start, graphEndDate,  hdsQueryBody, dateRange.interval || '1h', signal);
      return {data: hdsResponse, querySegmentLookup : _querySegmentLabelLookup};
    }
    } catch (err: any) {
      const regex = /abort/;
      if(!regex.test(err.message)){
        showError(err);
      }
    }
  };

  export {
    retrieveData,
    getQuerySegments,
    getConsumptionQuerySegments
  };

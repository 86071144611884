import React from "react";
import LoadingWidget from "../LoadingWidget";
import { useAuth0 } from "@auth0/auth0-react";

const EULAForm = ({ publicService }:{publicService:any}) => {
  /**
   * this is the EULA form
   * it will check if the user has accepted the EULA
   * while loading its invisible
   * if user has accepted, it will never shows and vanishes
   * if user has not accepted, it will show
   */
    const { logout } = useAuth0();

    const [loading, setLoading] = React.useState(false);
    const [accepted, setAccepted] = React.useState(false);
    const dataFetchedRef = React.useRef(false);

    const retrieveUser = async () => {
        dataFetchedRef.current = true;
        setLoading(true);
        const user = await publicService.getUser();
        if(user.accepted_eula) {
            setAccepted(true);
        }
        setLoading(false);
    };
    if(!dataFetchedRef.current) {
        retrieveUser();
    }

    const accept = async () => {
        setLoading(true);
        await publicService.acceptEULA();
        setAccepted(true);
        setLoading(false);
    }

    if(accepted) {
        return null;
    }
    if(loading) {
        return null;
    }


    return (<div className={'download-csv-modal eula-modal'}>
        <div data-testid="user-eula" className="modal-box">
            <h2>End User License Agreement</h2>
            <p>Please accept our End User License Agreement to continue. Our terms and conditions, as well as privacy policy, are linked here:</p>
            <a href="https://www.poweredbyelevation.com/curb-terms" data-testid="t_anc_c" target="_new">Terms and Conditions</a><br />
            <a href="https://www.poweredbyelevation.com/curb-privacy-policy" data-testid="privacy_policy" target="_new">Privacy Policy</a><br />
            <br />
            {!loading && <div className={'button-row'}>
            <button data-testid="accept-eula" onClick={async ()=>{
                await accept();
            }}>Accept</button>
            <button data-testid="reject-eula-logout" onClick={() => logout({ returnTo: window.location.origin })}>Reject</button>
            </div>}
            {loading && <div className={'button-row'}>
            <LoadingWidget message="Downloading CSV" vanish={false} />
            </div>}
        </div>
    </div>);
};

export default EULAForm;

export const checkEnvVars = () => {
  const errors:Error[] = [];
  if(!process.env.REACT_APP_PUBLIC_API_ROOT) {
    errors.push(new Error('Public API end point not set.'));
  }
  if(!process.env.REACT_APP_WEBAPP_CLIENT_ID) {
    errors.push(new Error('Auth0 Client ID not set.'));
  }
  if(!process.env.REACT_APP_AUTH_DOMAIN) {
    errors.push(new Error('Auth0 App Domain not set.'));
  }
  if(!process.env.REACT_APP_ENV) {
    errors.push(new Error('Environment is not set.'));
  }
  if(errors.length>0) {
    throw new AggregateError(errors, errors.reduce((acc,cur) => {
      acc+=`${cur.message}; `;
      return acc;
    },''));
  }
}

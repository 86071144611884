import * as d3 from 'd3';
import moment from 'moment-timezone';
import { formatterDate, formatterTime, formatterShortWeekDay, setUpBins,  } from './timeFunctions';

export type BarChartOptions = {
    [key:string]: any;
    dateRange: {
        start: Date;
        end: Date;
        range: string;
    },
    data: {
      start: string;
      interval_seconds: number;
      measure: string;
      series: {
        [key:string]: {
          [key:string]: number[]
        }
      }
    }[]
  };
  
  type GridItem = {
    date: Date;
    [key:string]: number | Date | null;
  };  

class BarChart {
    formatterNumber = new Intl.NumberFormat(undefined);
    palette = ['#00abd3','#fff18d','#f89f50','#c3f294','#d15056','#6b427c','#b5b5b5','#aef9ff','#d5c865','#df8b3d','#7cc368','#ffbbd4','#cc83dd','#888888','#dbdbdb','#6bad59','#eadc79','#2bdefc','#e9868e','#ffd7fc','#ffcea9'];
    dateDomain :string[] = [];
    xScale : any;
    yScale : any;
    colorDomain :any  = [];
    color : any;
    svg :any;
    options: BarChartOptions;
    yAxis :any;
    stackedData :any;
    dataMap: Map<number, { [key:string]: number | null; }>;
    intervalSecondsMap: Map<number, number>;
    grid: GridItem[];
    aggregatedSeries : any;
    xBins : Date[];
    querySegmentIds :any;
    inactiveSeries :any;
    startOfSeries :Date|undefined;
    constructor (options: BarChartOptions) {
        this.querySegmentIds = [];
        this.xBins = [];
        this.options = options;
        this.dataMap = new Map();
        this.intervalSecondsMap = new Map();
        this.grid = [];
        if(!options.data?.length){
            return;
        }
        this.inactiveSeries =  this.inactiveSeries || new Set();
        this.querySegmentIds = Array.from(
            options.data.reduce(
              (acc:Set<string>, {series}:any) => {
                if(typeof series === 'undefined'){ return acc; }
                for(const querySegmentId of Object.keys(series)){
                  acc.add(querySegmentId);
                }
                return acc;
              },
              new Set()
            )
          );
        this.startOfSeries = new Date(options.data[0].start);
        this.prepData();
        this.prepChartDeps();
        this.main();
    };
    async main () {
        this.updateStacks();
        this.createAxes();
    };
    resize () {
        this.options.chartConstants.width = window.innerWidth;
        this.svg = d3.select("#historicalChart").select('svg');
        this.svg?.append("g").attr('class', 'stacks');
        this.createScales();
        this.main();
    };
    prepData () {

        // Make sure there are no negative power values
        for(const result of this.options.data){
            if(typeof result === 'undefined' || typeof result.series === 'undefined'){ return; }
            for(const seriesKey of Object.keys(result.series)){
                result.series[seriesKey][this.options.measure] = result.series[seriesKey][this.options.measure].map(v => Math.max(0, v));
            }
        }

        // parse all results and create a flat map of dates and values
        for(const { start, interval_seconds, series } of this.options.data){
            const seriesLength = series[Object.keys(series)[0]][this.options.measure].length;
            const startDate = new Date(start);
            for(let i=0; i<seriesLength; i+=1){
                let d = new Date(startDate.getTime() + interval_seconds * i * 1000);
                this.intervalSecondsMap.set(d.getTime(), interval_seconds);
                let item = this.dataMap.get(d.getTime());
                if(!item){
                    item = { }; 
                    this.dataMap.set(d.getTime(), item);
                }
                for(const [querySegmentId, measures] of Object.entries(series)){
                    item[querySegmentId] = (measures[this.options.measure] || [])[i] ?? null;
                }
            }
        }

        this.dateDomain.push(...Array.from(this.dataMap.keys()).map(time => new Date(time).toISOString()));
        this.xBins = Array.from(this.dataMap.keys()).map(time => new Date(time));
        this.aggregateSeries();
    };
    aggregateSeries () {
        const totalSamples = this.xBins.length;
        this.aggregatedSeries = new Array(totalSamples).fill(0);
        this.grid = [];
        
        for(const [i, [time, values]] of Array.from(this.dataMap.entries()).entries()){
            const item : GridItem = this.grid[i] = { date: new Date(time) };
            for(const [querySegmentId, value] of Object.entries(values)){
                item[querySegmentId] = (value || 0)  * (this.inactiveSeries?.has(querySegmentId)? 0 : 1);
                if(!isNaN(value as number)){
                    this.aggregatedSeries[i] += (value || 0)  * (this.inactiveSeries?.has(querySegmentId)? 0 : 1);
                }
            }
        }
    };
    createScales () {
        this.xScale = d3.scaleBand()
            .domain(this.dateDomain)
            .range([0, this.options.chartConstants.width])
            .paddingInner(0.08);

        this.yScale = d3.scaleLinear()
            .domain([0, Math.max(...this.aggregatedSeries)])
            .range([ this.options.chartConstants.height, 0 ]);
    }
    prepChartDeps () {
        this.createScales();
        this.stackedData = d3.stack()
        .keys(this.querySegmentIds)
        .order((series: any) => {
          let n = series.length;
          const sum : {[key: string]: number;} = {};
          for (let i = 0; i < n; i++){
            sum[i.toString()] = series[i].reduce(
              (accumulator : any , currentValue : any ) => {
                const area = Math.abs(currentValue[1] - currentValue[0]);
                return accumulator + area;
              },0)
          }
          const rtn = Object.entries(sum).sort((a,b) => b[1]-a[1]).map((series : any) => parseInt(series[0], 10));
          this.colorDomain = rtn.map( ordinal => this.querySegmentIds[ordinal]);
          return rtn.reverse();
        })
        (this.grid as any[]);

        this.color = d3.scaleOrdinal()
          .domain(this.colorDomain)
          .range(this.palette);

          this.options.makeGraphLegend(this.options.querySegmentLookup, this.color);
        //SVG
        this.svg = d3.select("#historicalChart").select('svg');
        this.svg?.append("g").attr('class', 'stacks')
    };
    createAxes ()  {
        const  _yAxis = d3.axisLeft(this.yScale)
            .tickSizeInner(-1 * this.options.chartConstants.width);
        this.yAxis = this.svg?.append("g")
            .attr('class', 'y-axis')
            .call(_yAxis.tickFormat((d:any)=>{
                return this.formatterNumber.format(d/1000);
            }));
        this.svg.select('.y-axis .tick').remove();

    // now add axis ticks to overlay data
    const xAxisText = this.dateDomain.map((sampleDateString)=>{
        const d = new Date(sampleDateString);
        let rtn ='';
        switch(this.options.dateRange.range){
            case '3hr':
            case 'day':
                rtn = `${moment(d).tz(this.options.location.timezone).hours()}:${('0' + d.getMinutes()).slice(-2)}`;
                break;
            case 'week':
                rtn = moment(d).tz(this.options.location.timezone).toDate().toLocaleDateString();
                break;
            default:
                const intervalSeconds = this.intervalSecondsMap.get(d.getTime())!;
                const endSample = new Date(sampleDateString);
                endSample.setSeconds(endSample.getSeconds() + intervalSeconds);
                rtn = `${moment(d).tz(this.options.location.timezone).toDate().toLocaleDateString()} - ${moment(endSample).tz(this.options.location.timezone).toDate().toLocaleDateString()}`;
        }
        return rtn;
    });
    const xAxis = d3.axisBottom(this.xScale)
        .tickFormat((d,i)=>xAxisText[i]);
    this.svg.append("g")
      .attr('class', 'x-axis')
      .attr("transform", `translate(0, ${this.options.chartConstants.height})`)
      .call(xAxis)
      .call((g : any) => g.selectAll("line")
        .attr("stroke", "#666")
        .attr("stroke-opacity", 0.4));

    if(window.innerWidth < 500){
        this.svg.select('.x-axis') 
            .selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", function () {
                return "rotate(-60)";
            });
    }

    };
    mouseOverBarChart (event: any) {
        const series = event.target.parentElement.parentElement.getAttribute('class').split(' ').find((item:string) => !!/^series-.*$/ig.test(item)).replace(/^series-/,'');
        const data = event.target.__data__.data;
        const ttDate = `${formatterShortWeekDay.format(data?.date)} ${formatterDate.format(data?.date)}`;
        const ttTime = moment(data.date).tz(this.options.location.timezone);
        const seriesColor : any = this.color(series);
        const seriesConsumption =  data[series];
        const totalConsumption: any= Object.values(data).reduce((accumulator :any, currentValue: any) =>{return (!Number.isNaN(parseFloat(currentValue)))? accumulator + currentValue: accumulator;}, 0);
        const percentConsumption =  Math.floor(100 * seriesConsumption / totalConsumption);
        const rightEdgeFlag = (event.pageX > (window.innerWidth - 140)) ? -120 :0;
        const bottomEdgeFlag = (event.pageY > (window.innerHeight - 140)) ? -140 :0;

        d3.select('.chartToolTip')
            .style('top', `${event.pageY + bottomEdgeFlag}px`)
            .style('left', `${event.pageX + rightEdgeFlag}px`);
        d3.select('.ttDate').text(ttDate);
        d3.select('.ttTime').text(`${ttTime.format('h:mm A')}`);
        d3.select('.chartTTSeries')
            .text(this.options.querySegmentLookup[series])
            .style('background', seriesColor);
        const seriesConsumptionkWh = Math.floor((seriesConsumption/ 1000) * 100)/100;
        d3.select('.ttSeriesValue').text(`${seriesConsumptionkWh} `);
        d3.select('.ttMeasure').text(`${this.options.measure === 'p' ? 'watts' : 'kWh' }`);
        d3.select('.ttpercentTotal').text(`${percentConsumption}% `);
        const totalConsumptionkWh = Math.floor((totalConsumption/ 1000) * 100)/100;
        d3.select('.ttAllCircuitsValue').text(`${totalConsumptionkWh} `);
        d3.select('.ttAllCircuitsMeasure').text(`${this.options.measure === 'p' ? 'watts' : 'kWh' }`);
        d3.select('.chartToolTip').style('display', 'block');
      };
    mouseOutBarChart (event: any) {
        d3.select('.chartToolTip').style('display', 'none');
    };
    toggleSeries (seriesId : string) {
        if(seriesId === 'all') {
            this.inactiveSeries.clear();
        } else if (seriesId === 'none') {
            this.querySegmentIds.forEach((qsid: any)=>{
                this.inactiveSeries.add(qsid);
            });
        } else if(this.inactiveSeries.has(seriesId)){
            this.inactiveSeries.delete(seriesId);
        } else {
            this.inactiveSeries.add(seriesId);
        }
        this.aggregateSeries();
        const yMax = d3.max(this.aggregatedSeries);
        this.yScale.domain([0, yMax]);
        this.updateAxis();

        this.updateStacks();
    };
    updateAxis () {
        const  _yAxis = d3.axisLeft(this.yScale);
        this.yAxis.transition().duration(250).call(_yAxis.tickFormat((d:any)=>{
            return this.formatterNumber.format(d/1000);
        }));
        this.svg.select('.y-axis .tick').remove();
        this.svg.selectAll('.y-axis line').transition()
            .attr('x2', this.options.chartConstants.width);
    };
    updateStacks () {
        const stackData = d3.stack()
            .keys(this.querySegmentIds)
            .order((series: any) => {
            let n = series.length;
            const sum : {[key: string]: number;} = {};
            for (let i = 0; i < n; i++){
                sum[i.toString()] = series[i].reduce(
                (accumulator : any , currentValue : any ) => {
                    const area = Math.abs(currentValue[1] - currentValue[0]);
                    return accumulator + area;
                },0)
            }
            const rtn = Object.entries(sum).sort((a,b) => b[1]-a[1]).map((series : any) => parseInt(series[0], 10));
            return rtn.reverse();
            })
            (this.grid as any[]);
        stackData.forEach((stackedBar) => {
                stackedBar.forEach((stack :any, i :any) => {
                  stack.id = `${stackedBar.key}-${i}`;
                });
              });

        this.stackedData = stackData;
        const bars = this.svg
                .selectAll('.stacks')
                .selectAll('.qseg')
                .data(this.stackedData, (d:any) => { return d.key});

        bars.join(
            (enter : any) => {
                const barsEnter = enter.append("g").attr("class", (d: any)=>{return `qseg series-${d.key}`;})
                barsEnter
                .append("g")
                .attr("class", "bars")
                .attr("fill", (d:any) => {
                    return this.color(d.key);
                });
    
                this.updateRects(barsEnter.select(".bars"));
    
                return enter;
            },
            (update : any) => {
                const barsUpdate = update.select(".bars");
                this.updateRects(barsUpdate);
            },
            (exit :any) => {
                return exit.remove();
            }
            );
    }
    updateRects (childRects :any) {
        childRects
          .selectAll("rect")
          .data(
            (d :any) => d,
            (d :any) => d.id
          )
          .join(
            (enter :any) =>
              enter
                .append("rect")
                .attr("id", (d :any) => d.id)
                .attr("class", "bar")
                .attr("x", (d :any) => {return this.xScale(d.data.date.toISOString());})
                .attr("y", this.yScale(0))
                .attr("width", this.xScale.bandwidth())
                .on('mouseover', this.mouseOverBarChart.bind(this))
                .on('mouseout', this.mouseOutBarChart.bind(this))
                .call((enter :any) =>
                  enter
                    .transition()
                    .ease(d3.easeCubicOut)
                    .duration(250)
                    .attr("y", (d :any) => this.yScale(d[1]))
                    .attr("height", (d :any) => this.yScale(d[0]) - this.yScale(d[1]))
                ),
            (update :any) =>
              update.call((update :any) =>
                update
                  .transition()
                  .ease(d3.easeCubicOut)
                  .duration(250)
                  .attr("y", (d :any) => this.yScale(d[1]))
                  .attr("height", (d :any) => this.yScale(d[0]) - this.yScale(d[1]))
              ),
            (exit :any) =>
              exit.call((exit :any) =>
                exit
                .transition()
                .ease(d3.easeCubicOut)
                .duration(250)
                // .attr("y", this.options.chartConstants.height)
                .attr("height", this.yScale(0))
              )
          );
    };

};

export { 
    BarChart
};
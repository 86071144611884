import { useState, useEffect, useRef } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LoginButton from './components/LoginButton';
import 'react-tabs/style/react-tabs.css';
import LoadingScreen from './components/LoadingScreen';
import LocationDropdown from './components/LocationDropdown';
import Dashboard from './pages/Dashboard';

import { Auth0User, IAuth0User } from './entities/Auth0User';
import { checkEnvVars } from './config';
import { PublicServiceClient, LiveDataServiceClient } from './services';
import Menu from './components/Menu';

import { useAuth0 } from "@auth0/auth0-react";
import ErrorWidget from './components/ErrorWidget';
import AcceptClaim from './pages/AcceptClaim';


const App = ({mClaim, locationRedirect}:{mClaim:string|null, locationRedirect:string|null}) => {
  // will throw error if env vars missing
  checkEnvVars();
  const { user: authUser, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const placeHolder:any = {set:false};
  const [publicServiceClient, setPublicServiceClient] = useState(placeHolder);
  const [liveDataServiceClient, setLiveDataServiceClient] = useState(placeHolder);
  const placeHolder2:any[] = []
  const [usersLocations, setUsersLocations] = useState(placeHolder2);
  const mobileCheck = useRef(false);
  // The currently selected location
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationQuerySegment, setLocationQuerySegment] = useState(placeHolder);
  const [vanish, setVanish] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Checking Authentication");
  const deactiveLoadingScreen = () => {
    setVanish(true);
    setTimeout(() => {
      setShowLoadingScreen(false);
    }, 1000);
  }
  const activeLoadingScreen = () => {
    setShowLoadingScreen(true);
    setVanish(false);
  }
  const [authToken, setAuthToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const anyUser:IAuth0User = {
    email: '',
    email_verified: false,
    name: '',
    nickname: '',
    picture: '',
    sub: '',
    updated_at: ''
  };
  const [user, setUser] = useState(authUser||anyUser);

  const [tabIndex, setTabIndex] = useState(0);
  const undef:any = undefined;
  const [auth0, setAuth0] = useState(undef);

  const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  const prependUsersLocations = async (location:any) => {
    // console.log("This was the location? ", location, usersLocations);
    // setUsersLocations([location, ...usersLocations]);
    try {
      setTabIndex(1);
      const locs = await publicServiceClient.getUserLocations(user.sub);
      locs.reverse();
      setLocationId(location.uuid)
      setUsersLocations(locs);
      setLocation(locs[0]);
    }catch(err:any) {
      console.error(err);
      setErrorMessage(err.message);
      setTabIndex(2);
    }
  };

  useEffect(() => {

    if (!mobileCheck.current &&  isMobile() && (window.innerWidth / window.innerHeight < 1)) {
      // alert('You should rotate your mobile device to landscape.');
      mobileCheck.current = true;
    }

    (async function login() {
      if (!isLoading && !authUser && mClaim) {
        await loginWithRedirect({ screen_hint: "signup" });
      }
      else if (!isLoading && !authUser) {
        await loginWithRedirect();
      }
    })();
    const getData = async () => {
      setAuth0({
        getToken: getAccessTokenSilently,
        renewAuth: getAccessTokenSilently,
      });
      if(isAuthenticated) {
        try {  
          setLoadingMessage("Getting Locations")
          const user = Auth0User.factory(authUser||{});
          setUser(user);
          const token = await getAccessTokenSilently();
          setAuthToken(token);
          const ps = new PublicServiceClient({
            getToken: getAccessTokenSilently,
            renewAuth: getAccessTokenSilently,
          },token);
          setPublicServiceClient(ps);
          const ls = new LiveDataServiceClient(token);
          setLiveDataServiceClient(ls);
          if(user) {
            ps.setAuth({
              getToken: getAccessTokenSilently,
              renewAuth: getAccessTokenSilently,
            })
            if(mClaim) setTabIndex(3);
            else { 
              setTabIndex(1);
              let locs;
              try {
                if(locationRedirect) {
                  locs = [await ps.getLocation(locationRedirect)];
                } else {
                  locs = await ps.getUserLocations(user.sub);
                }
              } catch (err:any) {
                console.error(err);
                setErrorMessage('Failed to retrieve locations for user.');
                setVanish(true);
                setTimeout(() => {
                  setShowLoadingScreen(false);
                },1000);
                setTabIndex(2);
                return;
              }
              if(locs.message) {
                console.error(locs.message);
                setVanish(true);
                setTimeout(() => {
                  setShowLoadingScreen(false);
                },1000);
                setErrorMessage('Failed to retrieve locations for user.');
                setTabIndex(2);
                return;
              }
              setUsersLocations(locs);
              if(locs[0].uuid) {
                setLocationId(locs[0].uuid);
                setLocation(locs[0]);
                console.debug(`Setting location`, locs[0]);
              } else {
                setErrorMessage('Could not get locations for user.');
                setTabIndex(2);
              }
            }
          }
          deactiveLoadingScreen();
        } catch (err:any) {
          console.error(err);
          setErrorMessage(err.message);
        }
      }
      else if (!isAuthenticated && !isLoading){
        deactiveLoadingScreen();
      }
    }
    getData();
  },[isAuthenticated, isLoading, authUser, getAccessTokenSilently]);

  return (
    <div className='row'>
      <div className="App">
        {showLoadingScreen && <LoadingScreen vanish={vanish} message={loadingMessage} />}
          <div className={`header row  alignHeader`}>
              <div className={'align-logo-and-dropdown row'}>
                <div id="CurbLogo" className={`logo-header alignInHeader ${user.sub?'moveLogo':''}`}  />
                {user.sub && usersLocations && usersLocations.length > 20 &&
                  <LocationDropdown usersLocations={usersLocations} locationId={locationId} onChange={(arg:any)=>{setLocation(arg);setLocationId(arg.uuid);}} />}
              </div>
              {user.sub &&
                <Menu locationId={locationId} publicService={publicServiceClient} locationQuerySegment={locationQuerySegment} />}
          </div>
        <Tabs onSelect={()=>void(0)} selectedIndex={tabIndex}>
          <div style={{overflow:'hidden', height: 0, width: 0}}>
            <TabList>
              <Tab>Sign In</Tab>
              <Tab>Location Dashboard</Tab>
              <Tab>Errors</Tab>
              <Tab>Accepting Claims</Tab>
            </TabList>
          </div>
          <TabPanel>
            {!user.sub &&
              (<div style={{margin:"auto", textAlign: 'center'}}><p style={{textAlign:"center"}}>Redirecting.</p></div>)
            }
          </TabPanel>
          <TabPanel>
            <div className={`reveal ${user.sub?'now':''}`}>
              <Dashboard location={location} locationId={locationId} publicService={publicServiceClient} liveDataService={liveDataServiceClient} locationQuerySegment={locationQuerySegment} token={authToken} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className='small-screen-push-down'>
              <ErrorWidget 
                message={errorMessage}
                vanish={false}
                />
            </div>
          </TabPanel>
          <TabPanel>
            <div className='small-screen-push-down'>
              {mClaim && 
                <AcceptClaim 
                  publicService={publicServiceClient}
                  user={user}
                  mClaim={mClaim}
                  auth0={auth0}
                  prependUsersLocations={prependUsersLocations}
                  />}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )};

export default App;

import * as d3 from 'd3';

const getTimezoneOffset = (timeZone: string, date = new Date()) => {
    // https://stackoverflow.com/questions/20712419/get-utc-offset-from-timezone-in-javascript
    const tz = date.toLocaleString("en", {timeZone, timeStyle: "long"}).split(" ").slice(-1)[0];
    const dateString = date.toString();
    const offset = (Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`) )/ (1000 * 60 * 60);
    return offset;
  }


  const setUpBins = (initDate: Date, querySegmentSeries: any, measure: string, interval_seconds: number) => {
    const [firstQuerySegment] = Object.keys(querySegmentSeries);
    const s = initDate.valueOf();
    const xBins =  querySegmentSeries[firstQuerySegment][measure].map((e: any,i: number) => {
      return d3.utcParse("%Y-%m-%dT%H:%M:%S.%LZ")( (new Date(s + (1000 * i * interval_seconds))).toISOString());
    });
    return xBins;  
  };

  const formatterShortWeekDay = new Intl.DateTimeFormat(undefined, {weekday: 'short'});
  const formatterDate = new Intl.DateTimeFormat(undefined, {});
  const formatterTime = new Intl.DateTimeFormat(undefined, {hour: 'numeric', minute: 'numeric', hour12: true});

  export {
    getTimezoneOffset,
    setUpBins,
    formatterShortWeekDay,
    formatterDate,
    formatterTime
  };

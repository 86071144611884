import * as d3 from 'd3';
import moment from 'moment-timezone';
import { formatterDate, formatterTime, formatterShortWeekDay, getTimezoneOffset, setUpBins,  } from './timeFunctions';

export type NetPowerChartOptions = {
  [key:string]: any;
  data: {
    start: string;
    interval_seconds: number;
    measure: string;
    series: {
      [key:string]: {
        [key:string]: number[]
      }
    }
  }[]
};

const formatterNumber = new Intl.NumberFormat(undefined);
const renderNetPowerChart = (options: NetPowerChartOptions) =>{
  if(!options.data?.length){
    return;
  }

  const querySegmentIds = Array.from(
    options.data.reduce(
      (acc:Set<string>, {series}:any) => {
        for(const querySegmentId of Object.keys(series)){
          acc.add(querySegmentId);
        }
        return acc;
      },
      new Set()
    )
  );


  const sumstat : Map<string, number[]> = new Map();
  for(const querySegmentId of querySegmentIds){
    let collector :any = [];
    for(const { series } of options.data){
      const values = series[querySegmentId];
      if(values){
        const measureValues = values[options.measure];
        if(Array.isArray(measureValues)){
          collector = collector.concat(measureValues);
        }
      }
    }
    sumstat.set(querySegmentId, collector);
  }

  const dates : Date[] = [];
  for(const { start, interval_seconds, series } of options.data){
    const seriesLength = series[Object.keys(series)[0]][options.measure].length;
    const startDate = new Date(start);
    for(let i=0; i<seriesLength; i+=1){
      dates.push(new Date(startDate.getTime() + interval_seconds * i * 1000));
    }
  }
  dates.sort((a, b) => a.getTime() - b.getTime());
  const startDate = dates[0];
  const endDate = dates[dates.length-1];
  
  const x = d3.scaleUtc()
    .domain([startDate, endDate])
    .range([ 0, options.chartConstants.width ]);
    const consumptionMax = Math.max(...sumstat.get('consumption')!);
    const productionMax = sumstat.has('production') ? Math.abs(Math.min(...sumstat.get('production')!)) : 0;
    const domainMax = Math.max(consumptionMax, productionMax) * 1.2;
  const y = d3.scaleLinear()
    .domain([-1 * domainMax, domainMax])
    .range([ options.chartConstants.height, 0 ]);

  const color = d3.scaleOrdinal()
    .domain(['consumption', 'production', 'grid_net'])
    .range(['#ff8726', '#6dd152','#0046c0'])


  let overlap = false;
  //TODO might consider checking that the series are identical
  if(querySegmentIds.length === 2){
    overlap = true;
    d3.select('.tooltip-solar').style('width', '240px');
    d3.select('.label-production').style('display', 'none');
    d3.select('.measure-production').style('display', 'none');
  } else {
    d3.select('.tooltip-solar').style('width', '360px');
    d3.select('.label-production').style('display', 'block');
    d3.select('.measure-production').style('display', 'inline');
  }

  const lineGenerator = d3.line()
    .x((d: any, i: number )=>{
      const sampleTime = dates[i];
      return x(sampleTime); })
    .y((d: any)=>{ 
      //TODO 🤔 not sure this is the best way to deal with null values...
      return y(d ?? 0); });
  
  const svg = d3.select("#historicalChart").select('svg');
  svg
    .selectAll('series')
    .data(sumstat)
    .enter()
    .append('path')
    .style('fill', 'none')
    .style('stroke', (d:any)=>{return color(d[0]) as string;})
    .style('stroke-width', 2)
    .style("stroke-opacity", 0.9)
    .style('stroke-dasharray',(d:any)=>{
        return (overlap && d[0] === 'grid_net') ? '5,5' : null;
    })
    .attr("d", (d: any) => {
        return lineGenerator(d[1])
    });

  // now add axis ticks to overlay data
  let selectedTicks :any = [];
  let xTicks:any;
  const timezoneOffset = moment.tz(options.location.timezone).utcOffset() / 60;
  switch (options.dateRange.range){
    case '3hr':
      selectedTicks = d3.utcMinute.every(15);
      xTicks = d3.axisBottom(x).ticks(selectedTicks);
      break;
    case 'day':
      selectedTicks = d3.utcHour.every(3);
      xTicks = d3.axisBottom(x).ticks(selectedTicks);
      break;
    case 'week':
      selectedTicks = d3.utcHour.range(options.dateRange.start,options.dateRange.end).filter((d) => {
        const rtn = d.getUTCHours()===(-1 * timezoneOffset);
        return rtn;
      });
      xTicks = d3.axisBottom(x).tickValues(selectedTicks);
      break;
    case 'month':
      selectedTicks = d3.utcHour.range(options.dateRange.start,options.dateRange.end).filter((d) => {
        const rtn = (d.getUTCHours()===(-1 * timezoneOffset)) && d.getUTCDay()===0;
        return rtn;
      });
      xTicks = d3.axisBottom(x).tickValues(selectedTicks);
      break;
    case 'year':
      selectedTicks = d3.utcMonth.every(1);
      xTicks = d3.axisBottom(x).ticks(selectedTicks);
      break;
    };

  svg.append("g")
    .attr('class', 'x-axis')
    .attr("transform", `translate(0, ${options.chartConstants.height})`)
    .call(xTicks
      .tickFormat((d:any)=>{
        let formatPattern = 'hh:mma';
        switch (options.dateRange.range){
          case 'week':
          case 'month':
          case 'year':
            formatPattern = 'MMMM Do';
        }
        const label = moment(d).tz(options.location.timezone).format(formatPattern);
        return label;
      })
  )
    .call((g : any) => g.selectAll("line")
      .attr("stroke", "#666")
      .attr("stroke-opacity", 0.4));


  const yAxis = d3.axisLeft(y)
    .tickSizeInner(options.chartConstants.width);
  svg.append("g")
    .attr("transform", `translate(${options.chartConstants.width}, 0)`)
    .call(yAxis)
    .call((g : any) => g.selectAll("line")
      .attr("stroke", "#666")
      .attr("stroke-opacity", 0.4))
    .call( (g: any) => g.selectAll(".tick text")
    .attr("class", "yAxisText")
    .attr('transform','translate(36, 12)'));



    svg
    .append('rect')
    .style("fill", "none")
    .style("pointer-events", "all")
    .attr('width', options.chartConstants.width)
    .attr('height', options.chartConstants.height)
    .on('mousemove', mousemove);
    
  const graphCursor = svg
      .append('g')
      .append('line')
        .attr("stroke", "#666")
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', options.chartConstants.height)
        .style("opacity", 0.3);
// make dot for each series.
svg
  .selectAll("series")
  .data(querySegmentIds)
  .enter()
  .append("circle")
    .attr("stroke", "#aaa")
    .attr('r', 2)
    .attr('id', (d: any)=> `cursor-${d}`)
    

function mousemove(event: any) {
  console.log(dates);
  console.log(d3.pointer(event)[0]);
  d3.select('.tooltip-solar').style('display', 'block');
  const dateAtCursor = x.invert(d3.pointer(event)[0]);
  console.log('dateAtCursor', dateAtCursor.getTime());  
  const focusedDateIndex = d3.bisectRight(dates.map(d => d.getTime()), dateAtCursor.getTime());
  console.log('focusedDateIndex', focusedDateIndex);
  const dateAtFocus = dates[focusedDateIndex];
  console.log('dateAtFocus', dateAtFocus);
  const cursorDate = dateAtFocus.getDate() + ' ' + dateAtFocus.toLocaleString('default', { month: 'short' }) + ' ' 
    + dateAtFocus.getFullYear() + ' ' + String(dateAtFocus.getHours()).padStart(2,"0") + ':' + String(dateAtFocus.getMinutes()).padStart(2, '0');
  const ttDate = `${formatterShortWeekDay.format(dateAtFocus)} ${formatterDate.format(dateAtFocus)} `;
  graphCursor
  .attr("x1", x(dateAtFocus))
  .attr("x2", x(dateAtFocus));

  const ttmin = Math.max(event.pageX - 180, 0);
  const ttwidth = (querySegmentIds.length === 2)? 240 : 360;
  let toolTipLeft = Math.min( ttmin , (window.innerWidth - ttwidth));
  // console.log('tool tip left ', event.pageX , ttmin,  (window.innerWidth - ttwidth)  );
  const tooltip = d3.select('.tooltip-solar');
  tooltip.style('top', `${event.pageY}px`)
         .style('left', `${toolTipLeft}px`);
  tooltip.select('.ttDate').text(ttDate);
  tooltip.select('.chartDate').text(cursorDate);
  tooltip.select('.ttTime').text(`${ moment(dateAtFocus).tz(options.location.timezone).format('h:mm A')}`);

  for(const [i, [querySegmentId, values]] of Array.from(sumstat.entries()).entries()){
    const stackColor : any = color(querySegmentId);
    const cy = y(values[focusedDateIndex]);
    // console.log(`series ${key}, index ${index}, cy  ${cy}, ${sumstat.get(key)[index]}`);
    const measure = values[focusedDateIndex] || 0;
    const formattedMeasure = formatterNumber.format(Math.round(measure * 100)/100);
    tooltip.select(`.measure-${querySegmentId}`).text(`${formattedMeasure}W`);

    d3.select(`#cursor-${querySegmentId}`)
      .attr("fill", stackColor)
      .attr('cx', x(dateAtFocus))
      .attr('cy', cy);
  }

  // sumstat.forEach((value, key, map) =>{
  //   const stackColor : any = color(key);
  //   const index = Math.round((dateAtFocus.valueOf() - (new Date(options.data.start)).valueOf()) / (options.data.interval_seconds * 1000));
  //   const cy = y(sumstat.get(key)[index]);
  //   // console.log(`series ${key}, index ${index}, cy  ${cy}, ${sumstat.get(key)[index]}`);
  //   const measure = sumstat.get(key)[index] || 0;
  //   const formattedMeasure = formatterNumber.format(Math.round(measure * 100)/100);
  //   tooltip.select(`.measure-${key}`).text(`${formattedMeasure}W`);

  //   d3.select(`#cursor-${key}`)
  //     .attr("fill", stackColor)
  //     .attr('cx', x(dateAtFocus))
  //     .attr('cy', cy);
  //   });
  }

svg
.append('rect')
.style("fill", "none")
.style("pointer-events", "all")
.attr('width', options.chartConstants.width)
.attr('height', options.chartConstants.height)
.on('mousemove', mousemove);


  };

export {
  renderNetPowerChart    
};
import jwtDecode from 'jwt-decode';
import Claim from "../entities/claim";
import Location from "../entities/location";

class PublicService {
  auth:any;
  _url: string;
  _token: any;
  user_id: string;
  headers: Headers;
  setAuth(auth:any) {
    this.auth = auth;
  }

  async isExpired() {
    let token = this._token;
    // Auth0 updates and maintains the auth object seperately from the rest of the state we manage,
    // so one needs to check that this method exists before calling it.
    if(this.auth.getToken) token = await this.auth.getToken();
    try {
      const { exp }:any = jwtDecode(token);
      const now = Math.floor(Date.now() / 1000);
      // 20 second buffer
      return now > (exp - 20);
    } catch (err) {
      console.error('Error decoding JWT', err);
      return true;
    }
  };

  renewAuth() {
    try {
      return this.auth.renewAuth();
    } catch (err:any) {
      console.error('Error renewing auth', err);
      if (err.code === "login_required") {
        window.location.href = "/login";
      }
    }
  }

  constructor(auth:any, token:string){
    this._token = token;
    this.auth = auth;
    this._url = `${process.env.REACT_APP_PUBLIC_API_ROOT}`;
    let sub = '';
    try {
      // @ts-ignore
      const { sub:sub1 } = jwtDecode(this._token);
      sub = sub1;
    } catch (err) {
      console.error('Error decoding JWT', err);
    }
    this.user_id = sub;
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', `Bearer ${this._token}`);
    this.headers.append('Accept', 'application/json');
  }

  async getQuerySegmentsByLocation(location_id:string) {
    const url = `${this._url}locations/${location_id}/query-segments?include_registers=false&include_components=true`;
    if (await this.isExpired()) await this.renewAuth();
    const res = await fetch(url, {
      method: 'GET',
      headers: this.headers
    });
    const hubs = await res.json();
    return hubs;
  }

  async getHistoricalDataByQuerySegmentsForCSV(
    location_id:string,
    start_time:Date,
    end_time:Date,
    query_body:any) {
    const url = `${this._url}historical/location/${location_id}/timeseries?` +
      `interval=1h&interpolation=none&range_start=${start_time.toISOString()}&range_end=${end_time.toISOString()}`
      +`&query=${encodeURIComponent(JSON.stringify(query_body))}&include_query=false`;
    if (await this.isExpired()) await this.renewAuth();
    const res = await fetch(url, {
      method: 'GET',
      headers: this.headers
    });
    const hds = await res.json();
    return hds;
  }

  async getHistoricalDataByQuerySegmentsForChart(
    location_id:string,
    start_time:Date,
    end_time:Date,
    query_body:any,
    interval: string,
    signal:any) {
    const url = `${this._url}historical/location/${location_id}/timeseries?` +
      `interval=${interval}&interpolation=none&range_start=${start_time.toISOString()}&range_end=${end_time.toISOString()}`
      +`&query=${encodeURIComponent(JSON.stringify(query_body))}&include_query=false`;
    if (await this.isExpired()) await this.renewAuth();
    const res = await fetch(url, {
      method: 'GET',
      headers: this.headers,
      signal
    });
    const hds = await res.json();
    return hds;
  }


  async getUserLocations(user_id = this.user_id) {
    const url = `${this._url}users/${user_id}/locations`;
    if (await this.isExpired()) await this.renewAuth();
    const res = await fetch(url, {
      method: 'GET',
      headers: this.headers
    });
    const userLocations = await res.json();
    return userLocations;
  }

  async getUser(authId:string = this.user_id) {
    const url = `${this._url}user_details/${authId}`;
    if (await this.isExpired()) await this.renewAuth();
    const res = await fetch(url, {
      method: 'GET',
      headers: this.headers
    });
    const user = await res.json();
    return user;
  }

  async updateUser(user_id:string = this.user_id, body:any) {
    const url = `${this._url}user_details/${user_id}`;
    if (await this.isExpired()) await this.renewAuth();
    const res = await fetch(url, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(body)
    });
    const user = await res.json();
    return user;
  }

  async acceptEULA(user_id:string) {
    return await this.updateUser(user_id, { accepted_eula: true });
  }

  async activateClaimByCode(claimCode: string):Promise<Claim>{
    const resp = await fetch(`${this._url}claim/code/${claimCode}`, { method: 'GET', headers:this.headers});
    const data = await resp.json();
    const claim = Claim.factory(data);
    return claim;
  };

  async getLocationByClaim(claim: Claim):Promise<Location>{
    const resp = await fetch(
      `${this._url}location/${claim.object}`,
      { method: 'GET', headers: this.headers }
    );
    const data = await resp.json();
    const location = Location.factory(data);
    return location;
  }

  async getLocation(locationId:string):Promise<Location>{
    const resp = await fetch(
      `${this._url}location/${locationId}`,
      { method: 'GET', headers: this.headers }
    );
    const data = await resp.json();
    const location = Location.factory(data);
    return location;
  }

  async getInsights(locationId:string) {
    const resp = await fetch(
      `${this._url}insights/location/${locationId}`,
      { method: 'GET', headers: this.headers }
    );
    const data = await resp.json();
    return data;
  }
}

export default PublicService;

import { IAspectValue } from "./Insights";

const Aspect = ({aspect}:{aspect:IAspectValue}) => {
    if(!aspect) return null;
    const { key, label, value } = aspect;
    return (
        <div className="accordian-aspect-row" key={key}>
            <div className={`aspect-label asp-${key}`}>{label}</div>
            <div className={`aspect-value asp-${key} asp-${value}`}>{value}</div>
        </div>)
};
export default Aspect;